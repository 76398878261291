import { BsPatchCheckFill } from 'react-icons/bs'
import { BiErrorCircle } from 'react-icons/bi'

import './Steps.css'

const FinalStep = ({ thanksError }) => {
  return (
    <>
      {thanksError ? (
        <div className='steps'>
          <div className='step active super-step'>
            <BsPatchCheckFill />
            <p>Processo Finalizado</p>
          </div>
        </div>
      ) : (
        <div className='steps'>
          <div className='step active super-step'>
            <BiErrorCircle />
            <p>Erro</p>
          </div>
        </div>
      )}
    </>
  )
}

export default FinalStep
