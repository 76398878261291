// Components
import Footer from './components/Footer'
import HeaderPage from './components/HeaderPage'
import MainActions from './components/MainActions'

// Style
import './App.css'

function App() {
  return (
    <div className='app'>
      <div className='content-wrapper-center' id='home'>
        <HeaderPage />
        <MainActions />
      </div>
      <Footer />
    </div>
  )
}

export default App
