import React from 'react'
import { Modal } from '@mui/material'
import { MdClose } from 'react-icons/md'

import './ModalNivelConta.css' // Importe seu arquivo de estilo CSS

const ModalNivelConta = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} className='modal-wrapper'>
      <div className='modal-content'>
        <button className='close-button-modal' onClick={onClose}>
          <MdClose size={24} />
        </button>
        <div className='title-modal'>
          <h3>Você precisa ter conta nível Prata ou Ouro para abrir o seu MEI</h3>
          <p className='sub-title-modal'>Mas não se preocupe, é muito simples, basta seguir os passos abaixo</p>
        </div>
        <div className='steps-modal'>
          <div className='step-modal'>
            <div className='step-number-modal'>1</div>
            <div className='step-content-modal'>
              <h4>Faça o cadastro dentro do aplicativo Gov.br</h4>
              <p>
                Faça o download do aplicativo na{' '}
                <a
                  className='p-modal-link'
                  href='https://apps.apple.com/us/app/id1506827551'
                  target='_blank'
                  rel='noreferrer'
                >
                  App Store
                </a>{' '}
                ou{' '}
                <a
                  className='p-modal-link'
                  href='https://play.google.com/store/apps/details?id=br.gov.meugovbr&amp;hl=pt_BR'
                  target='_blank'
                  rel='noreferrer'
                >
                  Google Play
                </a>{' '}
                e siga as instruções para criar sua conta.
              </p>
            </div>
          </div>
          <div className='step-modal'>
            <div className='step-number-modal'>2</div>
            <div className='step-content-modal'>
              <h4>Aumente o nível da sua conta</h4>
              <p>No aplicativo Gov.br, clique na opção "Aumentar nível da conta" e siga as orientações.</p>
            </div>
          </div>
          <div className='step-modal'>
            <div className='step-number-modal'>3</div>
            <div className='step-content-modal'>
              <h4>Reconhecimento facial</h4>
              <p>Realize o reconhecimento facial pelo aplicativo para confirmar sua identidade com sua CNH.</p>
            </div>
          </div>
          <div className='step-modal'>
            <div className='step-number-modal'>4</div>
            <div className='step-content-modal'>
              <h4>Valide seus dados via internet banking</h4>
              <p>Utilize o internet banking de um banco credenciado para validar seus dados.</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalNivelConta
