import { useState } from 'react'

export function useForm(steps) {
  const [currentStep, setCurrentStep] = useState(0)

  function changeStep(i, e) {
    e.preventDefault()

    if (i < 0 || i >= steps.length) return

    setCurrentStep(i)
  }

  return {
    currentStep,
    currentComponents: steps[currentStep],
    changeStep,
    isLastStep: currentStep + 1 === steps.length ? true : false,
    isFirstStep: currentStep === 0 ? true : false
  }
}
