const BtnFinal = ({ handleStartAgain, thanksError }) => {
  return (
    <>
      {thanksError ? (
        <div className=' actions-button-last'>
          <button onClick={handleStartAgain}>
            <span>Cadastrar um novo MEI</span>
          </button>
        </div>
      ) : (
        <div className=' actions-button-last'>
          <button onClick={handleStartAgain}>
            <span>Tentar novamente</span>
          </button>
        </div>
      )}
    </>
  )
}

export default BtnFinal
