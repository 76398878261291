import React from 'react'
import LogoAtlantis from '../assets/LOGO_DIGITAL_PE.png'

import { AiOutlineInstagram, AiOutlineFacebook, AiOutlineYoutube } from 'react-icons/ai'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='content-wrapper-center row'>
        <section className='sm-12 md-9 lg-6'>
          <header>
            <a href='https://atlantisdigital.com.br' target='_blank' className='title-logo-final' rel='noreferrer'>
              <img className='logo-footer' alt='logo' src={LogoAtlantis} />
            </a>
          </header>
        </section>
        <section className='sm-12 md-3 lg-3'>
          <header>
            <h3>Redes Sociais</h3>
          </header>
          <ul className='icons-social-list'>
            <li>
              <a
                href='https://www.instagram.com/atlantiscontabilidade/'
                target='_blank'
                className='icons-social'
                rel='noreferrer'
              >
                <AiOutlineInstagram />
              </a>
            </li>
            <li>
              <a
                href='https://www.youtube.com/@AtlantisContabilidadeOnline'
                target='_blank'
                className='icons-social'
                rel='noreferrer'
              >
                <AiOutlineYoutube />
              </a>
            </li>
            <li>
              <a
                href='https://www.facebook.com/AtlantisContabilidade?locale=pt_BR'
                target='_blank'
                className='icons-social'
                rel='noreferrer'
              >
                <AiOutlineFacebook />
              </a>
            </li>
          </ul>
        </section>
      </div>
    </footer>
  )
}

export default Footer
