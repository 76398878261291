import React from 'react'
import { Grid, Radio, FormControlLabel } from '@mui/material'
import './ValidationForm.css'

const ValidationForm = ({ handleContaGovChange, handleNivelContaChange, contaGov, nivelConta }) => {
  return (
    <div className='validation-form'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4>Você possui acesso a Conta Gov.BR?</h4>
          <div className='spacing-margin'>
            <FormControlLabel
              name='conta_gov'
              control={<Radio value='sim' checked={contaGov === 'sim'} onChange={handleContaGovChange} />}
              label='Sim'
            />
            <FormControlLabel
              control={<Radio value='nao' checked={contaGov === 'nao'} onChange={handleContaGovChange} />}
              label='Não'
            />
          </div>
        </Grid>
        {contaGov === 'sim' ? (
          <Grid item xs={12}>
            <h4>
              Para abertura do MEI, a sua conta é preciso estar no mínimo nível Prata, sua conta está nível PRATA ou
              OURO?
            </h4>
            <div className='spacing-margin'>
              <FormControlLabel
                control={<Radio value='sim' checked={nivelConta === 'sim'} onChange={handleNivelContaChange} />}
                label='Sim'
              />
              <FormControlLabel
                control={<Radio value='nao' checked={nivelConta === 'nao'} onChange={handleNivelContaChange} />}
                label='Não'
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  )
}

export default ValidationForm
