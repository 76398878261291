import React from 'react'

import LogoAtlantis from '../assets/LOGO_DIGITAL_PE.png'

const HeaderPage = () => {
  return (
    <div>
      <header className='header'>
        <a href='index.html' className='logo'>
          <img className='logo-image' alt='logo' src={LogoAtlantis} />
        </a>
      </header>

      <section className='hero row'>
        <div className='content sm-12 lg-7'>
          <div className='title-header'>
            <div className='hero_title-container'>
              <h1 className='hero_title'>
                🚀 Empresários, abram seu CNPJ MEI de forma rápida e prática! Preencham nosso formulário agora e deem o
                primeiro passo para o sucesso do seu negócio! 💼📈
              </h1>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HeaderPage
