import React from 'react'

import './Thanks.css'

const Thanks = ({ thanksError, responseMessage }) => {
  return (
    <>
      {thanksError ? (
        <div className='thanks-container'>
          <h3>Recebemos sua solicitação de abertura de MEI</h3>
          <p>Processo de Abertura iniciado</p>
        </div>
      ) : (
        <div className='thanks-container'>
          <h3>Ocorreu um erro, tente novamente</h3>
          <p>{responseMessage}</p>
        </div>
      )}
    </>
  )
}

export default Thanks
