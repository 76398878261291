import React from "react";

import { openMEI } from "../service/serviceOpenMei";
import FinalStep from "../components/FinalStep";
import ModalNivelConta from "../components/ModalNivelConta";

import { useState } from "react";
import { useForm } from "../hooks/useForm";
import BtnFinal from "./BtnFinal";

// Mui

import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { FiSend } from "react-icons/fi";
import UserForm from "../components/UserForm";
import EmpresaForm from "./EmpresaForm";
import Thanks from "../components/Thanks";

import axios from "axios";
import Steps from "../components/Steps";
import ValidationForm from "../components/ValidationForm";

const MainActions = () => {
  const initialState = {
    cnpj_empresa: "24625767000174",
    nome: "",
    email: "",
    telefone: "",

    cpf: "",
    rg: "",
    data_nascimento: "",
    orgao_emissor: "",
    uf_emissor: "",
    senha_gov: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    municipio: "",
    bairro: "",
    forma_atuacao: "",
    atividade_principal: "",
    atividades_secundarias: [],
    capital_social: "1,00",
  };

  const [form, setForm] = useState(initialState);
  const [isEmptyFormm, setIsEmptyFormm] = useState(false);
  const [isEmptyCapitalSocial, setIsEmptyCapitalSocial] = useState(false);
  const [contaGov, setContaGov] = useState("");
  const [nivelConta, setNivelConta] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showCepAlert, setShowCepAlert] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [thanksError, setThanksError] = useState(false);
  const [showContaGovAlert, setShowContaGovAlert] = useState(false);
  const [showNivelContaAlert, setShowNivelContaAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState(
    "Ocorreu um erro tente novamente mais tarde"
  );

  // Functions

  const getAdress = async (e) => {
    const cep = e.target.value;
    const url = "https://viacep.com.br/ws/" + cep + "/json/";
    try {
      const response = await axios.get(url);
      setShowCepAlert(false); // Esconder o alerta de erro do CEP
      setForm({
        ...form,
        cep: cep,
        logradouro: response.data.logradouro,
        municipio: response.data.localidade,
      });
    } catch (error) {
      console.log(error);
      setShowCepAlert(true); // Mostrar o alerta de erro do CEP
    }
  };

  const handleChangeInput = (name, value) => {
    if (name === "cpf") {
      const formattedValue = value.replace(/\D/g, "");
      const cpfFormatted = formattedValue.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
        "$1.$2.$3-$4"
      );

      setForm((prev) => ({
        ...prev,
        cpf: cpfFormatted,
      }));
    } else if (name === "data_nascimento") {
      const formattedValue = value.replace(/\D/g, "");
      const dateFormatted = formattedValue.replace(
        /^(\d{2})(\d{2})(\d{4}).*/,
        "$1/$2/$3"
      );

      setForm((prev) => ({
        ...prev,
        [name]: dateFormatted,
      }));
    } else if (name === "rg") {
      const formattedValue = value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{1})$/,
        "$1.$2.$3-$4"
      );

      setForm({ ...form, rg: formattedValue });
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleChangeCapitalSocial = (event, value, maskedValue) => {
    event.preventDefault();

    setForm({ ...form, capital_social: maskedValue });
  };

  const handleAutocompleteChange = (_, newValue) => {
    setForm({ ...form, atividade_principal: newValue });
  };

  const handleAtividadeSecundaria = (e, newValue) => {
    setForm((prevForm) => ({ ...prevForm, atividades_secundarias: newValue }));
  };

  const handleContaGovChange = (event) => {
    const { value } = event.target;
    setContaGov(value);
    verifyHandleInputs(value, nivelConta);
  };

  const handleNivelContaChange = (event) => {
    const { value } = event.target;
    setNivelConta(value);
    verifyHandleInputs(contaGov, value);
  };

  const verifyHandleInputs = (contaGovValue, nivelContaValue) => {
    if (contaGovValue === "nao") {
      setIsButtonDisabled(true);
      setShowContaGovAlert(true);
      setShowNivelContaAlert(false);

      nivelContaValue = "";
    } else {
      setShowContaGovAlert(false);
    }

    if (nivelContaValue === "nao") {
      setIsButtonDisabled(true);
      setShowNivelContaAlert(true);
    } else {
      setShowNivelContaAlert(false);
    }

    if (contaGovValue !== "nao" && nivelContaValue !== "nao") {
      setIsButtonDisabled(false);
    }
  };

  const formComponents = [
    <ValidationForm
      handleContaGovChange={handleContaGovChange}
      handleNivelContaChange={handleNivelContaChange}
      contaGov={contaGov}
      nivelConta={nivelConta}
    />,
    <UserForm
      form={form}
      handleChangeInput={handleChangeInput}
      getAdress={getAdress}
      showCepAlert={showCepAlert}
      isEmptyFormm={isEmptyFormm}
    />,
    <EmpresaForm
      form={form}
      isEmptyCapitalSocial={isEmptyCapitalSocial}
      isEmptyFormm={isEmptyFormm}
      handleChangeCapitalSocial={handleChangeCapitalSocial}
      handleChangeInput={handleChangeInput}
      handleAutocompleteChange={handleAutocompleteChange}
      handleAtividadeSecundaria={handleAtividadeSecundaria}
    />,
  ];

  const {
    currentStep,
    currentComponents,
    changeStep,
    isLastStep,
    isFirstStep,
  } = useForm(formComponents);

  const [showAtividadesAlert, setShowAtividadesAlert] = useState(false);

  const [atividadesMessage, setAtividadesMessage] = useState("");

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    if (form.atividades_secundarias.length > 14) {
      console.log("Mais de 14 atividades secundárias");
      setShowAtividadesAlert(true);
      setAtividadesMessage(
        "Você pode selecionar no maximo 14 atividades secundarias"
      );
      return;
    }

    const atividadePrincipal = form.atividade_principal;
    const atividadesSecundarias = form.atividades_secundarias;
    const atividadesIguais = atividadesSecundarias.some(
      (atividade) => atividade === atividadePrincipal
    );

    if (atividadesIguais) {
      console.log("Atividades secundárias iguais à atividade principal");
      setShowAtividadesAlert(true);
      setAtividadesMessage(
        "A atividade principal nao pode ser igual a uma das secundarias"
      );
      return;
    }

    setShowAtividadesAlert(false);

    const { data_nascimento, ...formDataWithoutDate } = form;

    const formattedDate = data_nascimento.replace(/\D/g, "");
    const dateParts = formattedDate.match(/^(\d{2})(\d{2})(\d{4})$/);

    if (dateParts) {
      const year = parseInt(dateParts[3]);
      const month = parseInt(dateParts[2]) - 1; // Months are zero-based
      const day = parseInt(dateParts[1]);

      const parsedDate = new Date(year, month, day);

      const formData = {
        ...formDataWithoutDate,
        data_nascimento: parsedDate,
      };

      const response = await openMEI(formData);

      if (response.status === 200 && response.data.success === true) {
        setThanks(true);
        setThanksError(true);
        setResponseMessage(response.data.message);
        setStepsActive(false);
      } else {
        setStepsActive(false);
        setThanks(true);
        setResponseMessage(response.data.message);
        setThanksError(false);
      }
    } else {
      // Handle invalid date format
      console.log("Invalid date format");
    }
  };

  const handleStartAgain = (e) => {
    setStepsActive(true);
    setThanks(false);
    setIsButtonDisabled(true);
    setForm(initialState);
    changeStep(currentStep - 2, e);
    setContaGov("");
    setNivelConta("");
  };

  const [stepsActive, setStepsActive] = useState(true);

  const handleChangeStep = (e) => {
    e.preventDefault();

    const userFieldsToCheck = [
      "nome",
      "cpf",
      "email",
      "telefone",
      "data_nascimento",
      "senha_gov",
      "cep",
      "logradouro",
      "numero",
      "municipio",
    ];
    const anyUserFieldEmpty = userFieldsToCheck.some(
      (field) => form[field] === ""
    );

    if (currentStep === 1 && anyUserFieldEmpty) {
      setIsEmptyFormm(true);

      return;
    }
    changeStep(currentStep + 1, e);

    setIsEmptyFormm(false);
    setIsEmptyCapitalSocial(false);
  };

  const handleVerifyAndSendForm = (e) => {
    e.preventDefault();

    const empresaFieldsToCheck = [
      "rg",
      "orgao_emissor",
      "uf_emissor",
      "atividade_principal",
      "atividades_secundarias",
    ];

    const anyEmpresaFieldEmpty = empresaFieldsToCheck.some(
      (field) => form[field] === ""
    );

    if (form.capital_social === "R$ 0,00") {
      setIsEmptyCapitalSocial(true);
      setIsEmptyFormm(false);
      return;
    }

    if (currentStep >= 2 && anyEmpresaFieldEmpty) {
      setIsEmptyFormm(true);
      setIsEmptyCapitalSocial(false);

      return;
    }

    setIsEmptyFormm(false);
    setIsEmptyCapitalSocial(false);
    handleSubmitForm(e);
  };

  return (
    <>
      <article className="form-main " id="contato">
        <div>
          {stepsActive ? (
            <Steps currentStep={currentStep} />
          ) : (
            <FinalStep thanksError={thanksError} />
          )}
          <div className="alert-margin">
            {showContaGovAlert && (
              <Alert severity="error" color="error">
                <Typography>
                  Conta GOV é obrigatória para criar o MEI, crie sua conta
                  acessando{" "}
                  <a
                    className="typpography-class"
                    target="_blank"
                    href="https://www.gov.br/pt-br/servicos/criar-sua-conta-gov.br"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </Typography>
              </Alert>
            )}
            {showNivelContaAlert && (
              <ModalNivelConta
                open={showNivelContaAlert}
                onClose={() => setShowNivelContaAlert(false)}
              />
            )}
            {showAtividadesAlert && (
              <Alert severity="error" color="error">
                <Typography>{atividadesMessage}</Typography>
              </Alert>
            )}
          </div>
          <form onSubmit={handleChangeStep}>
            <div className="inputs-container">
              {thanks ? (
                <Thanks
                  responseMessage={responseMessage}
                  thanksError={thanksError}
                />
              ) : (
                currentComponents
              )}
            </div>
            {thanks ? (
              <BtnFinal
                handleStartAgain={handleStartAgain}
                thanksError={thanksError}
              />
            ) : (
              <div className="actions">
                {!isFirstStep && (
                  <button
                    type="button"
                    onClick={(e) => {
                      changeStep(currentStep - 1, e);
                    }}
                    disabled={isEmptyFormm}
                  >
                    <GrFormPrevious className="white-icon" />
                    <span>Voltar</span>
                  </button>
                )}
                {!isLastStep ? (
                  <button type="submit" disabled={isButtonDisabled}>
                    <span>Avançar</span>
                    <GrFormNext style={{ stroke: "white" }} />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => handleVerifyAndSendForm(e)}
                  >
                    <span>Enviar</span>
                    <FiSend color="white" />
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </article>
      <h1 className="hero-subtitle">
        📲 Além disso, durante todo o processo de abertura do seu CNPJ MEI,
        manteremos você atualizado pelo WhatsApp! Assim, você fica por dentro de
        cada etapa e tem a tranquilidade de saber que estamos cuidando de tudo
        para você.
      </h1>
    </>
  );
};

export default MainActions;
