import { BiSolidBusiness } from "react-icons/bi";

import { FaUserTie } from "react-icons/fa";

import { BsUiChecksGrid } from "react-icons/bs";

import "./Steps.css";

const Steps = ({ currentStep }) => {
  return (
    <div className="steps">
      <div className={`step ${currentStep === 0 ? "active" : ""}`}>
        <BsUiChecksGrid />
        <p>Validação</p>
      </div>
      <div className={`step ${currentStep === 1 ? "active" : ""}`}>
        <FaUserTie />
        <p>Dados Pessoais</p>
      </div>
      <div className={`step ${currentStep === 2 ? "active" : ""}`}>
        <BiSolidBusiness />
        <p>Empresa</p>
      </div>
    </div>
  );
};

export default Steps;
